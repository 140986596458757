<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper clone"
                 @click="$emit('close')">
                <div class="modal-container"
                     @click.stop>
                    <div class="modal-header">
                        <h2>{{$t('autoSavePage.title')}}</h2>
                        <button v-if="!allFieldsAreNull"
                                class="modal-default-button button button-primary"
                                @click="copy"
                                type="button">
                            {{$t('autoSavePage.recover')}}
                        </button>
                        <button class="modal-default-button button button-secondary"
                                @click="$emit('cancel')"
                                type="button">
                            {{$t('general.cancel')}}
                        </button>
                    </div>

                    <div class="modal-body"
                         v-if="!objectIsNull(selectedItem)">
                        <table>
                            <tbody>
                                <tr v-if="allFieldsAreNull">{{$t('autoSavePage.allFieldsAreNull')}}</tr>
                                <template v-if="selectedType == 'courseCatalog'">
                                    <tr is="field" 
                                        v-model="selectedItem.Description"
                                        :label="$t('commonLabels.description')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.EntryRequirements"
                                        :label="$t('commonLabels.entryCriteria')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.SignUpRules"
                                        :label="$t('commonLabels.signUpRules')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.ScheduleAndLocation"
                                        :label="$t('editPageLabels.timeAndPlace')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Attendance"
                                        :label="$t('commonLabels.presence')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Language"
                                        :label="$t('commonLabels.language')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.TeachingMethodsDescription"
                                        :label="$t('commonLabels.teachingMethodsRemarks')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.AssesmentMethodsDescription"
                                        :label="$t('editPageLabels.testTypeRemarks')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Contacttime"
                                        :label="$t('commonLabels.contactTime')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Coursematerials"
                                        :label="$t('commonLabels.studyMaterial')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.LearningObjectives"
                                        :label="$t('commonLabels.competencies')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.ProfessionalContext"
                                        :label="$t('commonLabels.practice')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.PortfolioProducts"
                                        :label="$t('commonLabels.portfolioProducts')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.CourseLinks"
                                        :label="$t('commonLabels.courseLinks')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.LearningTrack"
                                        :label="$t('commonLabels.learningTrack')"></tr>
                                </template>
                                <template v-else>
                                    <tr is="field"
                                        v-model="selectedItem.Description"
                                        :label="$t('programOverview.programmeDescription')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Image"
                                        :label="$t('programOverview.image')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.AdmissionRequirements"
                                        :label="$t('programOverview.admissionRequirements')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.ProgramStructure"
                                        :label="$t('programOverview.programmeStructure')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Video"
                                        :label="$t('programOverview.video')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Electives"
                                        :label="$t('programOverview.electives')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.FinalQualifications"
                                        :label="$t('programOverview.finalQualifications')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.TeachingAndExaminationReq"
                                        label="TER"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.AcademicCounselling"
                                        :label="$t('programOverview.studentCounselling')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.Internationalisation"
                                        :label="$t('programOverview.internationalisation')"></tr>
                                    <tr is="field" v-if="isExchange"
                                        v-model="selectedItem.Costs"
                                        :label="$t('programOverview.costs')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.EducationalConcept"
                                        :label="$t('programOverview.didacticMethod')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.TestingAndAssessments"
                                        :label="$t('programOverview.testingAndAssessment')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.QualityAssurance"
                                        :label="$t('programOverview.qualityAssurance')"></tr>
                                    <tr is="field"
                                        v-model="selectedItem.PracticalInformation"
                                        :label="$t('programOverview.practicalInformation')"></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button v-if="!allFieldsAreNull"
                                    class="modal-default-button button button-primary"
                                    @click="copy"
                                    type="button">
                                {{$t('autoSavePage.recover')}}
                            </button>
                            <button class="modal-default-button button button-secondary"
                                    @click="$emit('cancel')"
                                    type="button">
                                {{$t('general.cancel')}}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import field from "@/components/ClonePage/Field.vue";
    import { put } from 'common/store/storeFunctions.js'

    export default {
        name: "autosaveModal",
        components: { field },
        props: ["selectedItem", "selectedType"],
        computed: {
            allFieldsAreNull() {
                if (
                    this.objectIsNull(this.selectedItem) ||
                    (this.selectedType == "courseCatalog" &&
                        this.selectedItem.Description === null &&
                        this.selectedItem.EntryRequirements === null &&
                        this.selectedItem.SignUpRules === null &&
                        this.selectedItem.Language === null &&
                        this.selectedItem.TeachingMethodsDescription === null &&
                        this.selectedItem.Contacttime === null &&
                        this.selectedItem.Coursematerials === null &&
                        this.selectedItem.LearningObjectives === null &&
                        this.selectedItem.ProfessionalContext === null &&
                        this.selectedItem.PortfolioProducts === null &&
                        this.selectedItem.CourseLinks === null &&
                        this.selectedItem.LearningTrack === null) ||
                    (this.selectedType == "programInfo" &&
                        this.selectedItem.Description === null &&
                        this.selectedItem.Image == null &&
                        this.selectedItem.AdmissionRequirements === null &&
                        this.selectedItem.Video === null &&
                        this.selectedItem.ProgramStructure === null &&
                        this.selectedItem.FinalQualifications === null &&
                        this.selectedItem.EducationalConcept === null &&
                        this.selectedItem.TeachingAndExaminationReq === null &&
                        this.selectedItem.AcademicCounselling === null &&
                        this.selectedItem.TestingAndAssessments === null &&
                        this.selectedItem.Internationalisation === null &&
                        this.selectedItem.PracticalInformation === null)
                ) {
                    if (this.selectedType == "programInfo" && this.isExchange && this.selectedItem !== null) {
                        return false;
                    }
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods: {
            copy: async function () {
                var model = this;
                var autoSaveItem = this.$store.state.autoSaveItem;
                if (model.selectedType == "programInfo") {
                    autoSaveItem.Info.Description = model.selectedItem.Description;
                    autoSaveItem.Info.Image = model.selectedItem.Image;
                    autoSaveItem.Info.AdmissionRequirements = model.selectedItem.AdmissionRequirements;
                    autoSaveItem.Info.Video = model.selectedItem.Video;
                    autoSaveItem.Info.ProgramStructure = model.selectedItem.ProgramStructure;
                    autoSaveItem.Info.Electives = model.selectedItem.Electives;
                    autoSaveItem.Info.FinalQualifications = model.selectedItem.FinalQualifications;
                    autoSaveItem.Info.EducationalConcept = model.selectedItem.EducationalConcept;
                    autoSaveItem.Info.QualityAssurance = model.selectedItem.QualityAssurance;
                    autoSaveItem.Info.TeachingAndExaminationReq = model.selectedItem.TeachingAndExaminationReq;
                    autoSaveItem.Info.AcademicCounselling = model.selectedItem.AcademicCounselling;
                    autoSaveItem.Info.TestingAndAssessments = model.selectedItem.TestingAndAssessments;
                    autoSaveItem.Info.Internationalisation = model.selectedItem.Internationalisation;
                    autoSaveItem.Info.PracticalInformation = model.selectedItem.PracticalInformation;
                    if (this.isExchange) {
                        autoSaveItem.Info.Costs = model.selectedItem.Costs;
                    }
                    await put(`/api/program/update/${model.language}`, autoSaveItem);
                } else {
                    model.selectedItem.Id = autoSaveItem.Id;
                    model.selectedItem.ModifiedOn = autoSaveItem.ModifiedOn;
                    model.selectedItem.EditorialState = autoSaveItem.EditorialState;
                    model.selectedItem.Coordinators = autoSaveItem.Coordinators;
                    model.selectedItem.Lecturers = autoSaveItem.Lecturers;
                    await put(`/api/coursecatalog/update`, model.selectedItem);
                }
                model.$emit("close");
            }
        }
    };
</script>
<style lang="less" scoped>
    #app {
        .modal-mask

    {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 80%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
        max-height: 80vh;
        margin-top: 10%;
        margin-bottom: 10%;
        overflow-y: auto;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    a.readmore,
    a.readmore:visited {
        color: blue;
    }

    table {
        width: 100%;
    }

    .clone .modal-header {
        padding-left: 0;
        padding-right: 0;
    }

        .clone .modal-header h2 {
            color: #25167a;
            font-size: 1.25em;
        }

    .clone .modal-body,
    .clone .modal-footer {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-container {
        max-width: 1024px;
    }

    .hvaField {
        padding-bottom: 15px;
        display: inline-block;
        width: 100%;
    }

    }

    .bootstrap-hva .modal-header button {
        white-space: nowrap;
    }

    .bootstrap-hva .modal-header > :not(:last-child) {
        margin-right: 0.25rem;
    }
</style>
