<template>
    <div class="row">
        <div class="col-md-3">
            <div class="list-return">
                <a class="hva-caret-left" href="#" @click="$router.go(-1)">
                    {{$t('general.back')}}
                </a>
            </div>
        </div>
        <div class="col-md-9">
            <h2>{{$t('autoSavePage.title')}}</h2>
            <h3>{{$t('autoSavePage.select')}}</h3>
            <p>{{$t('autoSavePage.intro')}}</p>
            <spinner v-if="loading" />
            <template v-if="autoSaves !== null">
                <p v-if="autoSaves.length === 0">{{$t('autoSavePage.noAutoSaves')}}</p>
                <table class="list copy-course" v-else @reuse="copy">
                    <thead>
                        <tr>
                            <th class="sorter-false filter-false action" width="125">{{$t('autoSavePage.view')}}</th>
                            <th width="250">{{$t('autoSavePage.modifiedOn')}}</th>
                            <th v-if="futureEdit" width="250">{{$t('autoSavePage.modifiedBy')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in autoSaves" :key="item.Id + index">
                            <td class="action">
                                <button type="button" class="button-primary" @click="copy(item)">
                                    <font-awesome-icon icon="copy" />{{$t('autoSavePage.view')}}
                                </button>
                            </td>
                            <td>{{formatDate(item.Modified)}}</td> 
                            <td  v-if="futureEdit">{{item.Author}}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <autoSaveModal v-show="showModal" @close="close" @cancel="cancel" :selectedItem="selectedItem" :selectedType="itemType">

            </autoSaveModal>
        </div>
    </div>
</template>
<script>
    /*eslint-disable */
    import autoSaveModal from "@/components/Autosaves/AutosaveModal.vue";
    import Spinner from "common/components/General/Spinner.vue";
    import { get } from "common/store/storeFunctions";

    import "../../node_modules/tablesorter/dist/css/theme.default.min.css";
    var $ = require("jquery");
    require("tablesorter");

    export default {
        name: "autosaves",
        components: {
            Spinner,
            autoSaveModal,
        },
        data: function () {
            return {
                showModal: false,
                loading: true,
                itemId: null,
                searchstring: "",
                selectedItem: null,
                autoSaves: null,
                error: false,
                itemType: null,
                futureEdit: false
            };
        },
        async created() {
            var model = this;
            model.itemId = this.$store.state.autoSaveId;
            if (model.itemId == null) {
                model.loading = false;
                return;
            }
            model.itemType = this.$store.state.autoSaveType;
            if (model.itemType == "programInfo") {
                model.autoSaves = await get(`api/program/getautosaves/${model.itemId}`);
            } else {
                model.autoSaves = await get(`api/coursecatalog/getautosaves/${model.itemId}`);
            }
            model.loading = false;
        },
        updated() {
            this.initializeTablesorter();
        },
        methods: {
            formatDate: function (dateString) {
                var dateString = new Date(dateString).toUTCString();
                dateString = dateString.split(", ")[1];
                dateString = dateString.split("GMT")[0];
                return dateString;
            },
            initializeTablesorter: function () {
                $("table.list").tablesorter({
                    widgets: ["zebra"],
                    widthFixed: false,
                });
            },
             copy: async function (item) {
                this.loading = true;
                this.selectedItem = new Object();
                if (this.itemType == "programInfo") {
                    this.selectedItem = await get(`api/program/getautosave/${item.Id}`);
                } else {
                    this.selectedItem = await get(`api/coursecatalog/getautosave/${item.Id}`);
                }
                this.loading = false;
                this.showModal = true;
            },
            close: async function () {
                this.showModal = false;
                this.$router.go(-1);
            },
            cancel: function () {
                this.showModal = false;
            }
        }
    };
</script>

<style lang="less" scoped>
    #app {

    div.error {
        color: red;
        margin-top: 3px;
        margin-left: 3px;
        font-size: 14px;
    }

    .search-input {
        margin-right: 5px;
    }

    .hva-caret-left {
        margin-left: 26px;
    }

    .list-return {
        margin: 15px 0px;
    }

    h2 {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        font-size: 22px;
        font-weight: 400;
        color: #666;
        letter-spacing: 0.03em;
        text-transform: none;
        margin-top: 11px;
    }

    h3 {
        font-size: 1em;
        margin-top: 20px;
    }

    .search-text {
        padding: 2px 5px;
        height: 38px !important;
        font-size: 1rem;
        border: 1px solid #ced4da !important;
        border-radius: 3px 0 0 3px !important;
        border-right: none !important;
        width: 100%;
        max-width: 400px;
    }

    .tablesorter {
        margin-top: 25px;
    }
    }
</style>